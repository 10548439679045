import React from 'react'
import Layout from '../components/layout-en'
import Head from '../components/head'
import '../styles/contact-us.css'
import Swal from 'sweetalert2'

import {Row, Col} from 'react-bootstrap'

import Pesan from "../images/pesan.png";
import Farming from "../images/farming-3.png";

class ContactUs extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        submit: '',
      }
    }

    componentDidMount(){
        if (this.props.location.search == '?submit=True'){
            setTimeout(() => {
                Swal.fire({
                    title: "<div class='alert-title'>Thank You!</div>",
                    imageUrl: 'https://i.ibb.co/x5hFH0C/Group-5.png',
                    imageWidth: 35,
                    html:
                      "<div class='alert-text'>Your message has been sent.<br>" +
                      "We'll get back to you.<br><br></div>",
                    background: '#385723',
                    width: 450,
                    height: 300,
                    showCloseButton: true,
                    showConfirmButton: false,
                    focusConfirm: false,
                  });
            }, 0);
        } else if (this.props.location.search == '?submit=False'){
            this.setState({submit: 'false'});
        }
    }

    render(){

        return(

            <Layout>
                <Head title = "Contact-Us"/>
                <div id = "cu-main-container">

                    <div id = 'cu-section-container'>

                        <Col md id = "cu-col-1">

                            <img id = "cu-body-icon" src = {Pesan} alt = "pesan-img"/>

                            <div id = "cu-primary-title"> 
                                Talk To Us
                            </div>  

                            <div id = "sub-title">                       
                                We'd love to talk about how we can work together.
                            </div>

                            <div id = "description">
                                <div>We believe that to build a more resilient and sustainable agriculture, we need to work</div>
                                <div>together with leaders throughout the supply chain. If you share this vision and are</div>
                                <div> interested in joining this community of innovators, tell us more about yourself.</div>
                            </div>

                        </Col>

                        <Col id = "cu-col-2">

                            <Row id = "cu-row">

                                <div id = "cu-form-container">

                                    <div id = "cu-form-title">
                                        Fill out the form below
                                    </div>

                                    <form method="post" action="http://tania-notification-service.azurewebsites.net/website/v2/contact">
                                    
                                        <div id = "cu-form-section-1">
                                            
                                            <div id = "cu-form-section-1-title">Interested in:</div>

                                            <Row>

                                                <div id = "cu-checkbox-left">
                                                    
                                                    <label class="cu-checkbox-container">Dokter Tania
                                                        <input 
                                                            type="checkbox"
                                                            name="interest"
                                                            value="Dokter Tania"/>
                                                        <span class="cu-checkmark"></span>
                                                    </label>
                                                    
                                                    <label class="cu-checkbox-container">Enterprise Solutions
                                                        <input 
                                                            type="checkbox"
                                                            name="interest"
                                                            value="Enterprise Solutions"/>
                                                        <span class="cu-checkmark"></span>
                                                    </label>
                                                    
                                                    <label class="cu-checkbox-container">Smart/Precision Farming
                                                        <input 
                                                            type="checkbox"
                                                            name="interest"
                                                            value="Smart/Precision Farming"/>
                                                        <span class="cu-checkmark"></span>
                                                    </label>
                                                
                                                </div>

                                                <div id = "cu-checkbox-right">
                                                    
                                                    <label class="cu-checkbox-container">Media
                                                        <input 
                                                            type="checkbox"
                                                            name="interest"
                                                            value="Media"/>
                                                        <span class="cu-checkmark"></span>
                                                    </label>
                                                    
                                                    <label class="cu-checkbox-container">Employment
                                                        <input 
                                                            type="checkbox"
                                                            name="interest"
                                                            value="Employment"/>
                                                        <span class="cu-checkmark"></span>
                                                    </label>
                                                    
                                                    <label class="cu-checkbox-container">Other
                                                        <input 
                                                            type="checkbox"
                                                            name="interest"
                                                            value="Other"/>
                                                        <span class="cu-checkmark"></span>
                                                    </label>
                                                
                                                </div>
                                            
                                            </Row>
                                        
                                        </div>

                                        <div id = "cu-form-section-2">
                                            
                                            <div id = "cu-form-container">

                                                    <input class = "cu-input-field" 
                                                        type = "text" 
                                                        name = "name"
                                                        placeholder = "       First Name*"
                                                        id = "cu-form-first-name" required/>
                                                        
                                                    <input class = "cu-input-field" 
                                                        type = "text" 
                                                        name = "name"
                                                        placeholder = "       Last Name"
                                                        id = "cu-form-last-name"/>
                                                        
                                                    <br/>

                                                    <input class = "cu-input-field" 
                                                        type = "text" 
                                                        name = "company"
                                                        placeholder = "       Company*"
                                                        id = "cu-form-company" required/>
                                                        
                                                    <input class = "cu-input-field" 
                                                        type = "phone" 
                                                        name = "phone"
                                                        placeholder = "       Phone"
                                                        id = "cu-form-phone"/>
                                                        
                                                    <br/>
                                                        
                                                    <input class = "cu-input-field" 
                                                        type = "email" 
                                                        name = "email"
                                                        placeholder = "       Work Email*"
                                                        id = "cu-form-work-email" required/>

                                                    <br/>
                                                        
                                                    <textarea class = "cu-input-field" 
                                                        placeholder = "       Message*"
                                                        name = "message"
                                                        id = "cu-form-message" required/>

                                                    <br/>

                                                    <input 
                                                        type = "submit" 
                                                        value = "Submit"
                                                        onClick = "submit();" 
                                                        id = "cu-form-submit"/>
                                                    
                                                    <br/>

                                                    {this.state.submit==='false' && <div id="error-msg"> We are unable to send your message. Try again later</div>}
                                                                                
                                            </div>
                                    
                                        </div>

                                    {/* </form> */}
                                    </form>
                                
                                </div>
                                
                                <div id = "cu-body-img">

                                    <img id = "img-rectangle" src = {Farming} alt = "farming-img"/>

                                </div>

                            </Row>

                        </Col>

                    </div>

                </div>
            </Layout>

        );

    }

}

export default ContactUs;